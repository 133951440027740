<template>
  <div>
    <h1>리뷰작성</h1>
    <button class="" @click="image">사진</button>
    <button class="" @click="link">링크</button>
    <button class="" @click="frame">동영상</button>
    <div v-if="isLink">
      <p>
        링크입력:<input type="text" name="linkVal" v-model="linkVal" />
        <button class="" @click="addLink">확인</button>
      </p>
    </div>
    <div v-if="isFrame">
      <p>
        영상링크:<input type="text" name="linkVal" v-model="linkVal" />
        <button class="" @click="embed">확인</button>
      </p>
    </div>
    <vue-editor
      v-model="content"
      :editorToolbar="customToolbar"
      :useCustomImageHandler="false"
      @image-added="handleImageAdded"
    ></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      isFrame: false,
      isLink: false,
      content: "",
      linkVal: null,
      val: null,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        //["formula", "image", "video"],
        ["image", "video"],
        //["clean"], // remove formatting button
      ],
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    image() {
      document.querySelector(".ql-image").click(); //$refs 제외
    },
    link() {
      this.isLink = !this.isLink;
      this.isFrame = false;
    },
    frame() {
      this.isFrame = !this.isFrame;
      this.isLink = false;
    },
    embed() {
      const html = `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="${this.extractVideoUrl(
        this.linkVal
      )}"></iframe><p><br /></p>`;
      this.content += html;
      this.isFrame = false;
      this.linkVal = null;
      this.val = null;
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      //https://www.vue2editor.com/examples/#custom-image-handler
    },
    addLink() {
      const html = `<a href="${this.linkVal}" target="_blank">[ 관련내용 보기 ->]</a>`;
      this.content += html;
      this.isLink = false;
      this.linkVal = null;
      this.val = null;
    },
    extractVideoUrl(url) {
      var match =
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
        ) ||
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
        );
      if (match) {
        return (
          (match[1] || "https") +
          "://www.youtube.com/embed/" +
          match[2] +
          "?showinfo=0"
        );
      }
      if (
        (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))
      ) {
        // eslint-disable-line no-cond-assign
        return (
          (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
        );
      }
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}
button {
  margin-right: 5px;
  &:last-child {
    margin-right: 0px;
  }
}
.quillWrapper {
  margin: 20px 0 0 0;
  .ql-formats .ql-video {
    display: none;
  }
  .ql-formats .ql-image {
    display: none;
  }
}
</style>
